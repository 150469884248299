import axios from "axios";
import camelcaseKeys from "camelcase-keys";
import { decamelizeKeys } from "humps";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(`%c AJAX response error: ${error.response.status}`, "color: red");
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log("%c AJAX request error:", "color: red");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(`%c AJAX unknown error: ${error.message}`, "color: red");
    }

    console.log(error.config);

    const resp = error.response;
    if (resp) {
      error.customErrorMsg = (resp.data && resp.data.error) ?
        `Server error: ${resp.data.error}` : (resp.statusText || "Unknown error");
      console.log(`%c Custom error msg = ${error.customErrorMsg}`, "color: orange");
    }

    return Promise.reject(error);
  }
);

axios.interceptors.request.use((config) => {
  const newConfig = { ...config };
  if (newConfig.headers["Content-Type"] === "multipart/form-data") {
    return newConfig;
  }

  if (config.params) {
    newConfig.params = decamelizeKeys(config.params);
  }

  if (config.data) {
    newConfig.data = decamelizeKeys(config.data);
  }

  return newConfig;
});

export const createApi = (
  httpMethod,
  url,
  {
    headers = null,
    isUrlData = false,
    inputData = payload => payload,
    outputData = response => response
  } = {}
) => {
  return (payload) => {
    const axiosOptions = {
      method: httpMethod,
      url: url,
      responseType: "json",
      headers: headers || {
        Accept: "application/json",
        Authorization: localStorage.getItem("JWTTokenKey") || ""
      },
      transformResponse: [outputData]
    };

    if (httpMethod === "GET" || isUrlData) {
      axiosOptions.params = inputData(payload);
    } else {
      axiosOptions.data = inputData(payload);
    }

    return axios(axiosOptions);
  };
};

export const createDataApi = (httpMethod, url, params) => {
  return (payload) => {
    return createApi(httpMethod, url, params)(payload)
      .catch(() => {
        return Promise.reject({
          success: false,
          message: "Something went wrong"
        });
      })
      .then((response) => {
        return camelcaseKeys(response.data, { deep: true });
      })
      .then((body) => {
        if (body.success) {
          return body.data;
        }

        return Promise.reject(body);
      });
  };
};
