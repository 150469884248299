export const platforms = [
  {
    name: "whatsApp",
    title: "WhatsApp"
  },
  {
    name: "facebook",
    title: "Facebook"
  },
  {
    name: "chromeExtension",
    title: "Chrome"
  },
  {
    name: "safariExtension",
    title: "Safari"
  }
];
