import React from "react";
import PropTypes from "prop-types";
import "../../pages/Products/products.css";

const LanguageSelector = ({ availableLanguages, currentLanguage }) => {
  return (
    availableLanguages.map((language) => {
      const string = window.location.href.split("?")[1];
      const params = new URLSearchParams(string);

      params.set("locale", language);
      const newSearchString = params.toString();
      return (
        <React.Fragment key = { language }>
          <a href = { `/products?${newSearchString}` }
             rel = { "noreferrer noopener" }
             className = { `selectable-btn ${currentLanguage === language && "selectable-btn_selected"}` }>
            { language.toUpperCase() }
          </a>
        </React.Fragment>
      );
    })
  );
};

LanguageSelector.propTypes = {
  availableLanguages: PropTypes.array,
  currentLanguage: PropTypes.string
};

export default LanguageSelector;
