/* eslint-disable no-undef */

import { createDataApi } from "./createApi";

export const getAdminProductsStatistics = data => {
  return createDataApi(
    "GET",
    `${process.env.REACT_APP_API_HOST}/api/v1/admin/statistics/products`
  )(data);
};

