/* eslint-disable no-undef */
import { createApi } from "./createApi";

export const getProducts = data => {
  return createApi("GET", `${process.env.REACT_APP_API_HOST}/api/v1/products/products`)(data);
};

export const getDirectLink = data => {
  return createApi("GET", `${process.env.REACT_APP_API_HOST}/api/v1/products/products/direct_link`)(data);
};

export const trackInstallation = data => {
  return createApi("POST", `${process.env.REACT_APP_API_HOST}/api/v1/applications/installations`)(data);
};

export const trackDeletion = data => {
  return createApi("POST", `${process.env.REACT_APP_API_HOST}/api/v1/applications/deletions`)(data);
};
