import React from "react";
import PropTypes from "prop-types";
import { platforms } from "../../../data/platforms";
import "../statistics.css";

const Requests = ({
  productSearchCounts,
  productFoundCounts,
  productFoundWithPriceCounts
}) => {
  const totalCount = (counts) => {
    return Object.values(counts).reduce((sum, count) => sum + count);
  };

  const rows = [
    {
      title: "All products requested",
      counts: productSearchCounts
    },
    {
      title: "Where products were parsed",
      counts: productFoundCounts
    },
    {
      title: "Where price is found",
      counts: productFoundWithPriceCounts
    }
  ];

  return (
    <table className = { "statistics__table" }>
      <thead>
        <tr>
          <th>{"Count"}</th>
          <th>{"Total"}</th>
          {platforms.map((platform) => (
            <th key = { platform.name }>{ platform.title }</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <tr key = { row.title }>
            <th>{row.title}</th>
            <th>{ totalCount(row.counts) }</th>
            {platforms.map((platform) => (
              <td key = { platform.name }>
                { row.counts[platform.name] }
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

Requests.propTypes = {
  productSearchCounts: PropTypes.object,
  productFoundCounts: PropTypes.object,
  productFoundWithPriceCounts: PropTypes.object
};

export default Requests;
