import React from "react";
import PropTypes from "prop-types";

const FormattedNumber = ({ value }) => {
  const format = (value) => {
    if (value === undefined || value === null) {
      return "";
    }

    return new Intl.NumberFormat().format(Math.floor(value));
  };

  return <span>{format(value)}</span>;
};

FormattedNumber.propTypes = {
  value: PropTypes.number
};

export default FormattedNumber;
