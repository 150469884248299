import React from "react";
import PropTypes from "prop-types";
import ProductItem from "../product_item";
import { useIntl } from "react-intl";
import "../../pages/Products/products.css";

const ProductsTable = ({ products }) => {
  const intl = useIntl();
  const { formatMessage } = intl;

  return (
    <div>
      <div className = { "products__list" }>
        <div className = { "products__header products__row" }>
          <div className = { "products__inner" }>
            <div className = { "products__col" }></div>
            <div className = { "products__col" }>{ formatMessage({ id: "products.table.columns.name" }) }</div>
            <div className = { "products__col" }>{ formatMessage({ id: "products.table.columns.price" }) }</div>
            <div className = { "products__col" }>{ formatMessage({ id: "products.table.columns.rating" }) }</div>
            <div className = { "products__col" }></div>
          </div>
        </div>
        {products.map((item, index) => (
          <ProductItem item = { item } key = { index }/>
        ))}
      </div>
    </div>
  );
};

ProductsTable.propTypes = {
  products: PropTypes.array
};

export default ProductsTable;
