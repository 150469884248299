import React, { useState, useEffect } from "react";
import { getProducts, getDirectLink, trackInstallation, trackDeletion } from "../../requests";
import { useIntl } from "react-intl";
import Container from "../../components/container";
import Loader from "../../components/loader";
import "./products.css";
import SourcePrice from "../../components/source_price";
import LanguageSelector from "../../components/language_selector";
import ProductsList from "../../components/products_list";

const installationPath = "installation";
const deletionPath = "deletion";

const installationCallbackUrl = "https://myshoppingbot.de/installed";
const deletionCallbackUrl     = "https://myshoppingbot.de/deleted";

const availLanguages = ["en", "de"];

const Products = () => {
  const intl = useIntl();
  const { formatMessage, locale: currentLanguage } = intl;

  const [products, setProducts] = useState(undefined);
  const [hasPopularSources, setHasPopularSources] = useState(undefined);
  const [productsFromPopularSources, setProductsFromPopularSources] =
    useState(undefined);

  const [title, setTitle] = useState(undefined);
  const [, setPrice] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [priceInfo, setPriceInfo] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const needsShowPopularSources =
    hasPopularSources &&
    productsFromPopularSources &&
    productsFromPopularSources.length > 0;

  const checkHasQuery = () => {
    const string = window.location.href.split("?")[1];
    const params = new URLSearchParams(string);

    const title = params.get("query");
    const price = params.get("price");
    const source = params.get("source");
    const link  = params.get("link");

    const installationId = params.get("installation_id");
    const applicationType = params.get("application_type");
    const unitInfo = {
      installationId: installationId,
      applicationType: applicationType
    };

    if (string && title) {
      getProducts({
        query: title,
        price,
        source
      }).then(response => {
        const data = response.data.data;
        const {
          products,
          price: priceInfo,
          has_popular_sources: hasPopularSources,
          products_from_popular_sources: productsFromPopularSources
        } = data;

        setTitle(title);
        setPrice(price);
        setProducts(products);
        setHasPopularSources(hasPopularSources);
        setProductsFromPopularSources(productsFromPopularSources);
        setPriceInfo(priceInfo);

        window.document.title = formatMessage(
          { id: "shared.title_buy_item" },
          { item: title }
        );
        setLoading(false);
      })
        .catch(() => {
          setLoading(false);
          setError(formatMessage({ id: "error.unrecognized_error" }));
        });
    } else if (link) {
      getDirectLink({ link })
        .then(response => {
          const { product } = response.data.data;

          if (product && product.direct_link) {
            document.location.href = product.direct_link;
            return;
          }

          setLoading(false);
          setError(setError(formatMessage({ id: "error.invalid_direct_link" })));
        })
        .catch(() => {
          setLoading(false);
          setError(formatMessage({ id: "error.unrecognized_error" }));
        });
    } else if (window.location.pathname.includes(installationPath)) {
      trackInstallation({ installation: unitInfo })
        .then(() => {
          window.location.href = installationCallbackUrl;
        })
        .catch(() => {
          setLoading(false);
          setError(formatMessage({ id: "error.unrecognized_error" }));
        });
    } else if (window.location.pathname.includes(deletionPath)) {
      trackDeletion({ deletion: unitInfo })
        .then(() => {
          window.location.href = deletionCallbackUrl;
        })
        .catch(() => {
          setLoading(false);
          setError(formatMessage({ id: "error.unrecognized_error" }));
        });
    } else {
      setLoading(false);
      setError(formatMessage({ id: "error.unrecognized_error" }));
    }
  };

  useEffect(() => {
    checkHasQuery();
  }, []);

  return (
    <React.Fragment>
      { loading && <div className = { "products__overlay" }>
        <Loader/>
      </div> }
      { error && <div className = { "products__overlay" }>
        <h2 className = { "products__error-title" }>
          { formatMessage({ id: "error.title" }) }
        </h2>
        <span className = { "products__error-text" }>{ error }</span>
      </div> }
      <Container>
        <div className = { "title-block" }>
          <div className = { "title-block__texts" }>
            { title && <h1 className = { "title" }>{ title }</h1> }
            { priceInfo && (
              <SourcePrice formattedAmount = { priceInfo.formatted_amount }/>
            )}
          </div>
          <LanguageSelector availableLanguages = { availLanguages }
                            currentLanguage = { currentLanguage }/>
        </div>
        {needsShowPopularSources && (
          <div>
            <div>
              <p className = { "header" }>
                { formatMessage({ id: "products.popular_sources_title" }) }
              </p>
              <p className = { "subheader" }>
                { formatMessage({ id: "products.popular_sources_subtitle" }) }
              </p>
            </div>
            <ProductsList products = { productsFromPopularSources }
                          sortable = { false }/>
          </div>
        )}
        {products && (
          <div>
            {needsShowPopularSources && (
              <p className = { "header" }>
                { formatMessage({ id: "products.other_variants_title" }) }
              </p>
            )}
            <ProductsList products = { products }
                          sortable = { true }
                          sortParam = { "sortby" }/>
          </div>
        )}
      </Container>
    </React.Fragment>
  );
};

export default Products;
