import React, { useEffect, useState } from "react";
import Loader from "../../components/loader";
import Users from "../../components/statistics/users";
import Prices from "../../components/statistics/prices";
import Requests from "../../components/statistics/requests";
import { getAdminProductsStatistics } from "../../requests/dashboard";
import "./dashboard.css";
import ChatCountsPerDay from "../../components/statistics/chat_counts_per_day";

const Dashboard = () => {
  const [productsStatistics, setProductsStatistics] = useState(undefined);

  const [productsStatisticsError, setProductsStatisticsError] =
    useState(undefined);

  const [productsStatisticsLoading, setProductsStatisticsLoading] =
    useState(false);

  const loadProductsStatistics = () => {
    setProductsStatisticsLoading(true);
    getAdminProductsStatistics()
      .then((productsStatistics) => {
        setProductsStatistics(productsStatistics);
      })
      .catch((response) => {
        setProductsStatisticsError(response);
      })
      .finally(() => {
        setProductsStatisticsLoading(false);
      });
  };

  useEffect(loadProductsStatistics, []);

  const {
    usersCounts,
    productSearchCounts,
    productFoundCounts,
    productFoundWithPriceCounts,
    prices: {
      totalPrices,
      totalPricesWithAnalogs
    },
    savings: {
      totalMaximumSavings,
      totalRelevantSavings
    },
    chatsCounts: {
      facebookChatsCounts,
      whatsAppChatsCounts
    }
  } = productsStatistics || { prices: {}, savings: {}, chatsCounts: {} };

  return (
    <div className = { "dashboard" }>
      <h1>{"Dashboard"}</h1>
      <div>
        {productsStatisticsLoading && <Loader/>}
        {productsStatisticsError && (
          <div>
            <h3 className = { "products__error-title" }>{ "Error" }</h3>
            <span className = { "products__error-text" }>
              { productsStatisticsError.message }
            </span>
          </div>
        )}
        {productsStatistics && (
          <div>
            <div>
              <h3>{"Users"}</h3>
              <Users usersCounts = { usersCounts }/>
            </div>
            <div>
              <h3>{"Requests"}</h3>
              <Requests productSearchCounts = { productSearchCounts }
                        productFoundCounts = { productFoundCounts }
                        productFoundWithPriceCounts = { productFoundWithPriceCounts }/>
            </div>
            <div>
              <h3>{"Total prices"}</h3>
              <Prices totalPrices = { totalPrices }
                      totalPricesWithAnalogs = { totalPricesWithAnalogs }
                      totalMaximumSavings = { totalMaximumSavings }
                      totalRelevantSavings = { totalRelevantSavings }/>
            </div>
            <div>
              <h3>{"Chats per day"}</h3>
              <div>
                <div className = { "dashboard__inline" }>
                  <h4>{"Facebook"}</h4>
                  <ChatCountsPerDay days = { facebookChatsCounts }/>
                </div>
                <div className = { "dashboard__inline" }>
                  <h4>{"WhatsApp"}</h4>
                  <ChatCountsPerDay days = { whatsAppChatsCounts }/>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
