import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Products from "./pages/Products";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path = { "/products" }>
          <Products/>
        </Route>
        <Route path = { "/dashboard" }>
          <Dashboard/>
        </Route>
        <Route path = { "/" }>
          <Products/>
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
