import React from "react";
import PropTypes from "prop-types";
import "../statistics.css";

const ChatCountsPerDay = ({ days }) => {
  return (
    <table className = { "statistics__table" }>
      <thead>
        <tr>
          <th>{"Date"}</th>
          <th>{"New chats per day"}</th>
          <th>{"All chats per day"}</th>
        </tr>
      </thead>
      <tbody>
        {days.map((day) => (
          <tr key = { day.date }>
            <td>{day.date}</td>
            <td>{day.newChatsPerDay}</td>
            <td>{day.allChatsPerDay}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

ChatCountsPerDay.propTypes = {
  days: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string,
    newChatsPerDay: PropTypes.number,
    allChatsPerDay: PropTypes.number
  }))
};

export default ChatCountsPerDay;
