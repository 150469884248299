import React from "react";
import PropTypes from "prop-types";
import "../statistics.css";
import FormattedNumber from "../../formatted_number";

const Prices = ({
  totalPrices,
  totalPricesWithAnalogs,
  totalMaximumSavings,
  totalRelevantSavings
}) => {
  const currencies = Object.keys(totalPrices).sort();

  const rows = [
    {
      title: "All products requested",
      prices: totalPrices
    },
    {
      title: "Products with analogs",
      prices: totalPricesWithAnalogs
    },
    {
      title: "Savings by minimum price",
      prices: totalMaximumSavings
    },
    {
      title: "Savings by relevant product",
      prices: totalRelevantSavings
    }
  ];

  return (
    <table className = { "statistics__table" }>
      <thead>
        <tr>
          <th>{"Amount"}</th>
          {currencies.map((currency) => (
            <th key = { currency }>{ currency.toUpperCase() }</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <tr key = { row.title }>
            <th>{row.title}</th>
            {currencies.map((currency) => (
              <td key = { currency }>
                <FormattedNumber value = { row.prices[currency] }/>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

Prices.propTypes = {
  totalPrices: PropTypes.object,
  totalPricesWithAnalogs: PropTypes.object,
  totalMaximumSavings: PropTypes.object,
  totalRelevantSavings: PropTypes.object
};

export default Prices;
