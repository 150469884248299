import React from "react";
import ReactStars from "react-stars";
import { useIntl } from "react-intl";

const ProductItem = props => {
  const { item } = props;
  const intl = useIntl();
  const { formatMessage } = intl;

  return (
    <div className = { "products__row" }>
      <div className = { "products__inner" }>
        <div className = { "products__col" }>
          <img src = { item.thumbnail }
               className = { "products__img" }
               alt = { item.title }/>
        </div>
        <div className = { "products__col" }>
          <a className = { "products__title" }
             href = { item.internal_relative_direct_link }
             target = { "_blank" }
             rel = { "noreferrer noopener" }>
            { item.title }
          </a>
        </div>
        <div className = { "products__col" }>
          <p className = { "products__price" }>
            { item.price }
          </p>
          <span className = { "products__source" }>
            { item.source }
          </span>
        </div>
        <div className = { "products__col" }>
          { item.rating && <ReactStars value = { item.rating }
                                       half = { true }
                                       edit = { false }
                                       className = { "products__stars" }/> }
        </div>
        <div className = { "products__col" }>
          <a className = { "products__link" }
             href = { item.internal_relative_direct_link }
             target = { "_blank" }
             rel = { "noreferrer noopener" }>
            { formatMessage({ id: "action.go_to_shop" }) }
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProductItem;
