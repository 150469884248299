import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import "../../pages/Products/products.css";

const SourcePrice = ({ formattedAmount }) => {
  const intl = useIntl();
  const { formatMessage } = intl;

  return (
    <h3 className = { "subtitle" }>
      {
        formatMessage(
          { id: "products.source_price" },
          { price: formattedAmount }
        )
      }
    </h3>
  );
};

SourcePrice.propTypes = {
  formattedAmount: PropTypes.string
};

export default SourcePrice;
