import React from "react";
import PropTypes from "prop-types";
import { platforms } from "../../../data/platforms";
import "../statistics.css";

const Users = ({ usersCounts }) => {
  const totalCount = () => {
    return Object.values(usersCounts).reduce((sum, count) => sum + count);
  };

  return (
    <table className = { "statistics__table" }>
      <thead>
        <tr>
          <th>{"Total"}</th>
          {platforms.map((platform) => (
            <td key = { platform.name }>{platform.title}</td>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{totalCount()}</td>
          {platforms.map((platform) => (
            <td key = { platform.name }>{usersCounts[platform.name]}</td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};

Users.propTypes = {
  usersCounts: PropTypes.object
};

export default Users;
