import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import "../../pages/Products/products.css";

const OrderSelector = ({ relevanceSorting, priceSorting, onChange }) => {
  const intl = useIntl();
  const { formatMessage } = intl;

  return (
    <div className = { "sorting-block" }>
      <span className = { "sorting-block__text" }>{ `${formatMessage({ id: "shared.sort_by" })}:` }</span>
      <div>
        <button className = { `selectable-btn ${relevanceSorting && "selectable-btn_selected"}` }
                onClick = { () => onChange("relevance") }>
          { formatMessage({ id: "shared.relevance" }) }
        </button>
        {"/"}
        <button className = { `selectable-btn ${priceSorting && "selectable-btn_selected"}` }
                onClick = { () => onChange("price") }>
          { formatMessage({ id: "shared.price" }) }
        </button>
      </div>
    </div>
  );
};

OrderSelector.propTypes = {
  relevanceSorting: PropTypes.bool,
  priceSorting: PropTypes.bool,
  onChange: PropTypes.func
};

export default OrderSelector;
