import React from "react";
import { IntlProvider } from "react-intl";

import translations from "./i18n/locales";

import App from "./App";

const AppWrapper = () => {
  const findLocale = () => {
    const string = window.location.href.split("?")[1];
    const params = new URLSearchParams(string);

    let locale = params.get("locale");
    if (locale && locale !== "") {
      return locale;
    }

    let userLang = navigator.language || navigator.userLanguage;
    return userLang;
  };

  const setupLocale = () => {
    const locale = findLocale();

    let messages = translations[locale];
    if (messages) {
      return {
        messages,
        locale
      };
    }

    const shortLocaleCode = locale.split("-")[0];
    messages = translations[shortLocaleCode];
    if (messages) {
      return {
        messages,
        locale: shortLocaleCode
      };
    }

    return {
      locale: "en",
      messages: translations.en
    };
  };

  const { locale, messages } = setupLocale();

  return (
    <IntlProvider key = { locale }
                  locale = { locale }
                  messages = { messages }>
      <App/>
    </IntlProvider>
  );
};

export default AppWrapper;
