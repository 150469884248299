import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "../../pages/Products/products.css";
import OrderSelector from "../order_selector";
import ProductsTable from "../products_table";

const ProductsList = ({
  products: originalProducts,
  sortParam = "sortby",
  sortable = true
}) => {
  const [relevanceSorting, setRelevanceSorting] = useState(true);
  const [priceSorting, setPriceSorting] = useState(false);
  const [products, setProducts] = useState(undefined);

  const defaultSortBy = "relevance";
  const columnsByOrder = {
    price: "price_position",
    relevance: "relevance_position"
  };

  useEffect(() => {
    if (!sortable) {
      setProducts(originalProducts);
      return;
    }

    const sortBy = getSortByFromUrl();
    onSortChange(sortBy);
  }, [originalProducts]);

  const getSortByFromUrl = () => {
    const string = window.location.href.split("?")[1];
    const params = new URLSearchParams(string);
    let sortBy = params.get(sortParam);

    if (columnsByOrder[sortBy] === undefined) {
      sortBy = defaultSortBy;
    }

    return sortBy;
  };

  const onSortChange = sortBy => {
    const column = columnsByOrder[sortBy];
    const products = sortProducts(column);
    setSortedProducts(products, sortBy);
  };

  const sortProducts = (column) => {
    return originalProducts.slice().sort((a, b) => {
      return a[column] - b[column];
    });
  };

  const setSortedProducts = (products, sortBy) => {
    setPriceSorting(sortBy === "price");
    setRelevanceSorting(sortBy === "relevance");
    setProducts(products);
    replaceParameter(sortParam, sortBy);
  };

  const replaceParameter = (parameter, value) => {
    const string = window.location.href.split("?")[1];
    const params = new URLSearchParams(string);
    params.set(parameter, value);
    window.history.replaceState("", "", `products?${params.toString()}`);
  };

  return (
    <div>
      {sortable && (
        <OrderSelector relevanceSorting = { relevanceSorting }
                       priceSorting = { priceSorting }
                       onChange = { (order) => onSortChange(order) }/>
      )}
      {products && <ProductsTable products = { products }/>}
    </div>
  );
};

ProductsList.propTypes = {
  products: PropTypes.array,
  sortParam: PropTypes.string,
  sortable: PropTypes.bool
};

export default ProductsList;
